<template>
  <section id="Header">
    <div id="header_top_list" v-if="!isScroll">
      <ul>
        <li @click="goToUrl(a.url)" v-for="a in ASIDEMENU" :key="a.id">
          {{a.title}}
        </li>
      </ul>
    </div>
    <div id="overlay" v-if="asideOpen"></div>
    <div id="aside" :class="{ open: asideOpen }">
        <i class="el-icon-close" @click="toggleAside"></i>
      <div class="aside_header">
        <div class="logo">
          <img class="logo_img" src="../assets/images/logo.png">
        </div>
        <div class="aside_menu">
          <ul>
            <li v-for="a in ASIDEMENU" :key="a.id" >
              <a :href="a.url">
                {{a.title}}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="aside_box">
        <div class="aside_top">
          <ul>
            <li v-for="m in MENU" :key="m.id" >
              <p>{{m.title}}</p>
            </li>
          </ul>
        </div>
        <div class="aside_bottom">
          <div class="title">
            <p>고객센터</p>
            <i class="el-icon-plus" @click="toggleCategory"></i>
          </div>
          <div class="categoryList" v-if="category">
            <ul>
              <li v-for="c in CATEGORY" :key="c.id">
                <p>{{c.title}}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="header_box">
      <div class="header_top">
        <div class="left_icon" @click="toggleAside">
          <img src="../assets/images/menu_icon.png">
        </div>
        <div class="logo" @click="$router.push('/')" style="cursor: pointer">
          <img src="../assets/images/logo.png">
        </div>
        <div class="right_icon">
          <i class="el-icon-search"></i>
          <i class="el-icon-user"></i>
          <i class="el-icon-goods"></i>
        </div>
      </div>
      <div class="header_bottom">
        <ul>
          <li v-for="m in MENU" :key="m.id" >
            <a :href="m.url">
              {{m.title}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
  const MENU = [
    {
      id:'1',
      title:'Necklace',
      url:''
    },
    {
      id:'2',
      title:'Earring',
      url:''
    },
    {
      id:'3',
      title:'Ring',
      url:''
    },
    {
      id:'4',
      title:'Bracelet',
      url:''
    },
    {
      id:'5',
      title:'Sale',
      url:''
    }
  ]
  const ASIDEMENU = [
    {
      id:'1',
      title:'회원가입',
      url:'/signup'
    },
    {
      id:'2',
      title:'로그인',
      url:'/login'
    },
    {
      id:'3',
      title:'주문조회',
      url:''
    },
    {
      id:'4',
      title:'장바구니',
      url:''
    }
  ]
  const CATEGORY = [
    {
      id:'1',
      title:'공지사항',
      url:''
    },
    {
      id:'2',
      title:'상품사용후기',
      url:''
    },
//    {
//      id:'3',
//      title:'상품Q&A',
//      url:''
//    },
    {
      id:'4',
      title:'1대1문의',
      url:''
    }
  ]
  export default {
    name: "Header",
    data(){
      return{
        MENU,ASIDEMENU,CATEGORY,
        asideOpen: false,category:false,isScroll:false
      }
    },
    methods:{
      goToUrl(url){
        this.$router.push(url)
      },
      toggleAside(){
        this.asideOpen = !this.asideOpen;
      },
      toggleCategory(){
        this.category = !this.category;
      },
      handleScroll(){
        this.isScroll = window.scrollY > 0;
      }
    },
    mounted(){
      window.addEventListener('scroll',this.handleScroll)
    }
  }
</script>