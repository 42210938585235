<template>
  <div id="app">
    <componet :is="layout">
      <router-view></router-view>
    </componet>
  </div>
</template>

<script>
import NoLayout from "@/layout/NoLayout";

export default {
  name: 'App',
  components: {
    NoLayout
  },
  computed:{
    layout(){
      const layoutType = this.$route.meta.layout;
      return layoutType ? layoutType + 'Layout' : 'NoLayout'
    }
  }
}
</script>