import Vue from 'vue'
import Router from 'vue-router'
import HomeRouter from './modules/Home'
import SignRouter from './modules/Sign'

Vue.use(Router)

const routes = [
  ...HomeRouter,
  ...SignRouter
]

const router = new Router({
  mode:'history',
  routes:routes
})

export default router;
