import { auth } from '@/firebase';

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user ? { uid: user.uid, email: user.email } : null;
    },
  },
  actions: {
    async signUp({ commit }, { email, password }) {
      try {
        const { user } = await auth.createUserWithEmailAndPassword(email, password);
        commit('setUser', user);
      } catch (error) {
        console.error(error.message);
        throw error;
      }
    },
    async signIn({ commit }, { email, password }) {
      try {
        const { user } = await auth.signInWithEmailAndPassword(email, password);
        commit('setUser', user);
      } catch (error) {
        console.error(error.message);
        throw error;
      }
    },
    async signOut({ commit }) {
      try {
        await auth.signOut();
        commit('setUser', null);
      } catch (error) {
        console.error(error.message);
        throw error;
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
  },
};